
const toggleSpMenu = () => {
    const btn = document.getElementById('js-hamburger-btn');

    if(!btn) return;

    const body = document.body;

    btn.addEventListener('click', () => {
        body.classList.toggle('is-sp-nav-open');
    })
}
toggleSpMenu();

const closeSpMenu = () => {
    const bg = document.getElementById('js-close-sp-nav');

    if(!bg) return;

    const body = document.body;

    bg.addEventListener('click', () => {
        body.classList.remove('is-sp-nav-open');
    })
}
closeSpMenu();